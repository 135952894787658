<template>
  <div>
    <a-card title="产品信息">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <ClientSelect v-model="searchForm.clients" placeholder="客户" @change="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <CategorySelect v-model="searchForm.category" placeholder="分类" @change="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="产品编号, 名称" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <a-col :span="24" :md="8" :xl="10" style="margin-bottom: 12px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">模板下载</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">导入新增</a-button>
            </a-upload>
            <a-upload name="file" :showUploadList="false" :customRequest="importUpdateExcel">
              <a-button icon="upload">导入更新</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">导出</a-button>
            <a-button icon="barcode" @click="bulkPrint">批量打印</a-button>
          </a-button-group>
        </a-col>
        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="openFormModal(form)">新增产品</a-button>
        </div>
      </a-row>
      <a-row>
        <a-table
          size="small"
          rowKey="number"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ x: 1080, y: 480 }"
          @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? "激活" : "冻结" }}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button icon="barcode" size="small" @click="labelPrint(item)">打印</a-button>
              <a-button icon="edit" size="small" @click="openFormModal(item)">编辑</a-button>
              <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <form-modal v-model="visible" :form="targetItem" @create="create" @update="update" />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import { materialsTemplate, materialsImport, materialsImportUpdate } from "@/api/import";
import { materialList, materialDestroy } from "@/api/material";
import { getMaterialsNumber } from "@/api/data";
import { getLodop } from "@/assets/LodopFuncs";
import { materialsExport } from "@/api/export";
import { unitOption } from "@/api/option";
import { exportExcel } from "@/utils/excel";
import columns from "./columns.js";

export default {
  components: {
    FormModal: () => import("./FormModal.vue"),
    ClientSelect: () => import("@/components/ClientSelect/"),
    CategorySelect: () => import("@/components/CategorySelect/"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", page: 1, page_size: 48, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 48 },
      loading: false,
      items: [],

      visible: false,
      targetItem: {},
      form: {
        inventory_warning: false,
        stock_out_strategy: "fifo",
        enable_shelf_life: false,
        enable_batch_control: true,
        is_active: true,
      },
      importLoading: false,
      unitItems: [],
      selectedRowKeys: [],
      selectionRows: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    materialLabelWidth() {
      return this.$store.state.user.barConfig.materialLabelWidth;
    },
    materialLabelLength() {
      return this.$store.state.user.barConfig.materialLabelLength;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();

      unitOption({ page_size: 999999 }).then((data) => {
        this.unitItems = data.results;
      });
    },
    list() {
      this.loading = true;
      materialList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create(item) {
      this.items.splice(0, 0, item);
    },
    update(item) {
      this.items.splice(
        this.items.findIndex((i) => i.id == item.id),
        1,
        item
      );
    },
    destroy(id) {
      materialDestroy({ id }).then(() => {
        this.items.splice(
          this.items.findIndex((item) => item.id == id),
          1
        );
        this.$message.success("删除成功");
      });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    openFormModal(item) {
      if (!item.id) {
        getMaterialsNumber().then((data) => {
          this.targetItem = { ...item, ...{ number: data.number } };
        });
      } else {
        this.targetItem = { ...item };
      }
      console.log(this.targetItem);
      this.visible = true;
    },
    exportExcel() {
      materialsExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, "产品列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      materialsTemplate()
        .then((resp) => {
          exportExcel(resp, "产品导入模板");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        materialsImport(data)
          .then(() => {
            this.$message.success("导入成功");
            this.list();
          })
          .catch((err) => {
            alert("导入错误:\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    importUpdateExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        materialsImportUpdate(data)
          .then(() => {
            this.$message.success("导入成功");
            this.list();
          })
          .catch((err) => {
            alert("导入错误:\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    labelPrint(item) {
      if (!this.materialLabelLength || !this.materialLabelWidth) {
        this.$message.warning("请先在标签配置中设置标签参数!");
        return false;
      }
      let LODOP = getLodop();
      LODOP.PRINT_INIT("");
      // LODOP.ADD_PRINT_TEXT(10, 34, 260, 30, '名称：' + item.name)
      // LODOP.ADD_PRINT_BARCODE(38, 34, this.materialLabelLength, this.materialLabelWidth, "128A", item.number);

      LODOP.SET_PRINT_PAGESIZE(1, `${this.materialLabelLength}mm`, `${this.materialLabelWidth}mm`);
      LODOP.ADD_PRINT_BARCODE(
        `${this.materialLabelWidth * 0.15}mm`,
        `${this.materialLabelLength * 0.15}mm`,
        `${this.materialLabelLength * 0.7}mm`,
        `${this.materialLabelWidth * 0.7}mm`,
        "128A",
        item.number
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 20);

      LODOP.PREVIEW();
    },
    bulkPrint() {
      if (!this.items.length) {
        this.$message.warning("暂无打印的数据!");
        return false;
      }
      if (!this.materialLabelLength || !this.materialLabelWidth) {
        this.$message.warning("请先在标签配置中设置标签参数!");
        return false;
      }
      let LODOP = getLodop();
      LODOP.PRINT_INIT("");
      LODOP.SET_PRINT_PAGESIZE(1, `${this.materialLabelLength}mm`, `${this.materialLabelWidth}mm`);
      this.items.map((item, idx) => {
        LODOP.NewPage();
        LODOP.ADD_PRINT_BARCODE(
          `${this.materialLabelWidth * 0.15}mm`,
          `${this.materialLabelLength * 0.15}mm`,
          `${this.materialLabelLength * 0.7}mm`,
          `${this.materialLabelWidth * 0.7}mm`,
          "128A",
          item.number
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 20);

        // LODOP.ADD_PRINT_BARCODE(38 + idx * (this.materialLabelWidth + 20), 34, this.materialLabelLength, this.materialLabelWidth, "128A", item.number);
      });
      LODOP.PREVIEW();
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
